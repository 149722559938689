import { getFunctions } from "firebase/functions";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyD9X_yZpUahWFIDxO09wuWgKcIcWc8Jq_E",
  authDomain: "mysocraft-a456a.firebaseapp.com",
  projectId: "mysocraft-a456a",
  storageBucket: "mysocraft-a456a.appspot.com",
  messagingSenderId: "240493745662",
  appId: "1:240493745662:web:d947789c4af5140e361caf",
  measurementId: "G-PTBPHJ1QRP",
};

const app = initializeApp(firebaseConfig);
export const functions = getFunctions(app, "europe-west6");

export default app;

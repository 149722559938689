import { Layer } from "../types/layer";

const ContentLayer = ({ children }: Layer): JSX.Element => {
  return (
    <div className="content-layer">
      {children}
    </div>
  );
};

export default ContentLayer;

import { CardProps } from "../types/card";

const Card = ({ title, children }: CardProps): JSX.Element => {
  return (
    <div className="card">
      <header>
        <h2>{title}</h2>
      </header>
      <div className="body">
        {children}
      </div>
    </div>
  );
};

export default Card;

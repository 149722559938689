import { Layer } from "../types/layer";

const ActionsLayer = ({ children }: Layer): JSX.Element => {
  return (
    <div className="actions-layer">
      {children}
    </div>
  );
};

export default ActionsLayer;

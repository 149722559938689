import logo from "../images/logo.svg";

const Navbar = (): JSX.Element => {
  return (
    <div className="navbar">
      <img
        onClick={() => window.location.href = ""}
        alt="socraft"
        src={logo}
      />
    </div>
  );
};

export default Navbar;

import { Layer } from "../types/layer";

const PageLayer = ({ children }: Layer): JSX.Element => {
  return (
    <div className="page-layer">
      {children}
    </div>
  );
};

export default PageLayer;

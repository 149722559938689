import { IconChartBar, IconCoin, IconListDetails, IconArrowRight } from "@tabler/icons-react";
import withReactContent from "sweetalert2-react-content";
import ContentLayer from "./layers/ContentLayer";
import ActionsLayer from "./layers/ActionsLayer";
import TablesLayer from "./layers/TablesLayer";
import texts from "./static/buttonTexts.json";
import PageLayer from "./layers/PageLayer";
import Contact from "./components/Contact";
import Navbar from "./components/Navbar";
import Card from "./components/Card";
import { useState } from "react";
import Swal from "sweetalert2";

const App = (): JSX.Element => {
  const [employerContributionRateOnSalary, setEmployerContributionRateOnSalary] = useState<number>(20);
  const [desiredActivityRate, setDesiredActivityRate] = useState<number>(100);
  const [clientType, setClientType] = useState<keyof TextsType | null>(null);
  const [numberOfPaidDaysOff, setNumberOfPaidDaysOff] = useState<number>(25);
  const [annualWorkableDays, setAnnualWorkableDays] = useState<number>(250);
  const [grossAnnualSalary, setGrossAnnualSalary] = useState<number>();
  const [occupencyRate, setOccupencyRate] = useState<number>(100);
  const [annualDaysOff, setAnnualDaysOff] = useState<number>(25);
  const [socraftMargin, setSocraftMargin] = useState<number>(10);

  const handleAction = (): void => {
    withReactContent(Swal).fire({
      html: (
        <Contact
          title={texts[clientType!]}
          clientType={texts[clientType!]}
        />
      ),
      showCancelButton: false,
      showConfirmButton: false,
      customClass: "popup"
    });
  };

  return (
    <>
      <Navbar />
      <PageLayer>
        <header>
          <h1>Simulateur employé / indépendant</h1>
          <div className="client-selector">
            <button
              onClick={() => setClientType("crafter")}
              className={`option ${clientType === "crafter" && 'active'}`}>
              Je suis employé / indépendant
            </button>
            <button
              onClick={() => setClientType("client")}
              className={`option ${clientType === "client" && 'active'}`}>
              Je suis employeur
            </button>
          </div>
        </header>
        <ContentLayer>
          <div className={`column ${clientType === null && 'disabled'}`}>
            <Card title="Employeur">
              <label htmlFor="occupencyRate">Taux d'activité (%) :</label>
              <div className="input-group">
                <IconChartBar size={33} />
                <input
                  type="number"
                  id="occupencyRate"
                  placeholder="..."
                  disabled={clientType === null}
                  value={occupencyRate}
                  onChange={(event) => setOccupencyRate(event.target.valueAsNumber)}
                />
              </div>
              <br />
              <label htmlFor="grossAnnualSalary">Salaire annuel brut :</label>
              <div className="input-group">
                <IconCoin size={33} />
                <input
                  type="number"
                  id="grossAnnualSalary"
                  placeholder="..."
                  disabled={clientType === null}
                  value={grossAnnualSalary}
                  onChange={(event) => setGrossAnnualSalary(event.target.valueAsNumber)}
                />
              </div>
              <br />
              <label htmlFor="employerContributionRateOnSalary">Taux de charges employeurs sur salaire (%) :</label>
              <div className="input-group">
                <IconChartBar size={33} />
                <input
                  type="number"
                  id="employerContributionRateOnSalary"
                  placeholder="..."
                  disabled={clientType === null}
                  value={employerContributionRateOnSalary}
                  onChange={(event) => setEmployerContributionRateOnSalary(event.target.valueAsNumber)}
                />
              </div>
              <br />
              <label htmlFor="companyBudget">Budget annuel de l'employeur pour le poste :</label>
              <div className="input-group">
                <IconChartBar size={33} />
                <input
                  type="number"
                  id="companyBudget"
                  placeholder="..."
                  disabled={clientType === null}
                  readOnly
                  value={grossAnnualSalary! + (grossAnnualSalary! * employerContributionRateOnSalary! / 100)}
                />
              </div>
              <br />
              <label htmlFor="annualWorkableDays">Nombre de jours annuels ouvrables :</label>
              <div className="input-group">
                <IconListDetails size={33} />
                <input
                  type="number"
                  id="annualWorkableDays"
                  placeholder="..."
                  disabled={clientType === null}
                  value={annualWorkableDays}
                  onChange={(event) => setAnnualWorkableDays(event.target.valueAsNumber)}
                />
              </div>
              <br />
              <label htmlFor="annualDaysOff">Nombre de jours annuels de congés payés :</label>
              <div className="input-group">
                <IconListDetails size={33} />
                <input
                  type="number"
                  id="annualDaysOff"
                  placeholder="..."
                  disabled={clientType === null}
                  value={annualDaysOff}
                  onChange={(event) => setAnnualDaysOff(event.target.valueAsNumber)}
                />
              </div>
              <br />
              <label htmlFor="annualWorkingDays">Nombre de jours annuels travaillés :</label>
              <div className="input-group">
                <IconListDetails size={33} />
                <input
                  type="number"
                  id="annualWorkingDays"
                  placeholder="..."
                  disabled={clientType === null}
                  readOnly
                  value={annualWorkableDays! - annualDaysOff!}
                />
              </div>
            </Card>
          </div>
          <div className={`column ${clientType === null && 'disabled'}`}>
            <Card title="Employé / Indépendant">
              <label htmlFor="desiredActivityRate">Taux d'activité souhaité (%) :</label>
              <div className="input-group">
                <IconChartBar size={33} />
                <input
                  type="number"
                  id="desiredActivityRate"
                  placeholder="..."
                  disabled={clientType === null}
                  value={desiredActivityRate}
                  onChange={(event) => setDesiredActivityRate(event.target.valueAsNumber)}
                />
              </div>
              <br />
              <label htmlFor="numberOfAnnualWorkableDays">Nombre de jours annuels ouvrés :</label>
              <div className="input-group">
                <IconListDetails size={33} />
                <input
                  type="number"
                  id="numberOfAnnualWorkableDays"
                  placeholder="..."
                  disabled={clientType === null}
                  readOnly
                  value={annualWorkableDays! * desiredActivityRate! / 100}
                />
              </div>
              <br />
              <label htmlFor="numberOfPaidDaysOff">Nombre de jours de congés payés :</label>
              <div className="input-group">
                <IconListDetails size={33} />
                <input
                  type="number"
                  id="numberOfPaidDaysOff"
                  placeholder="..."
                  disabled={clientType === null}
                  value={numberOfPaidDaysOff}
                  onChange={(event) => setNumberOfPaidDaysOff(event.target.valueAsNumber)}
                />
              </div>
              <br />
              <label htmlFor="numberOfAnnualWorkingDays">Nombre de jours annuels travaillés :</label>
              <div className="input-group">
                <IconListDetails size={33} />
                <input
                  type="number"
                  id="numberOfAnnualWorkingDays"
                  placeholder="..."
                  disabled={clientType === null}
                  readOnly
                  value={(annualWorkableDays! * desiredActivityRate! / 100) - numberOfPaidDaysOff!}
                />
              </div>
              <br />
              <label htmlFor="socraftMargin">Marge socraft variable pour bons et loyaux services (%) :</label>
              <div className="input-group">
                <IconChartBar size={33} />
                <input
                  type="number"
                  id="socraftMargin"
                  placeholder="..."
                  disabled={clientType === null}
                  value={socraftMargin}
                  onChange={(event) => setSocraftMargin(event.target.valueAsNumber)}
                />
              </div>
              {/* <br />
              <label htmlFor="gender">Je suis :</label>
              <div className="input-group">
                <IconGenderBigender size={33} />
                <select
                  id="gender"
                  onChange={(event) => setGender(event.target.value)}
                  value={gender}>
                  <option value="male">Un homme</option>
                  <option value="female">Une femme</option>
                </select>
              </div> */}
            </Card>
          </div>
          <TablesLayer>
            <table className="results">
              <tr>
                <th>En tant qu'employé</th>
                <th>Annuel</th>
                <th>Mensuel</th>
                <th>Journalier</th>
              </tr>
              <tr>
                <td>Salaire employé brut</td>
                <td>CHF {Math.round(grossAnnualSalary! || 0)}.-</td>
                <td>CHF {Math.round((grossAnnualSalary! || 0) / 12)}.-</td>
                <td>CHF {Math.round((grossAnnualSalary! || 0) / (annualWorkableDays! * desiredActivityRate! / 100))}.-</td>
              </tr>
              <tr>
                <td>Salaire employé net *</td>
                <td>CHF {Math.round((grossAnnualSalary! || 0) - (((grossAnnualSalary! || 0) * 15) / 100))}.-</td>
                <td>CHF {Math.round(((grossAnnualSalary! || 0) - (((grossAnnualSalary! || 0) * 15) / 100)) / 12)}.-</td>
                <td>CHF {Math.round(((grossAnnualSalary! || 0) - (((grossAnnualSalary! || 0) * 15) / 100)) / (annualWorkableDays! * desiredActivityRate! / 100))}.-</td>
              </tr>
            </table>
            <span>* Charges sociales employé : AVS/AI/APG (5,30%), AC (1,10%), IJM (Ho : 0,58%, Fe : 1,01%), LPP (moy. 6%), PCFam (0,06%)</span>
            <table>
              <tr>
                <th>En tant qu'indépendant</th>
                <th>Annuel</th>
                <th>Mensuel</th>
                <th>Journalier</th>
              </tr>
              <tr>
                <td>Montant facturé au client</td>
                <td>CHF {(Math.round(desiredActivityRate * (grossAnnualSalary! + (grossAnnualSalary! * employerContributionRateOnSalary! / 100)) / 100) || 0)}.-</td>
                <td>CHF {(Math.round((desiredActivityRate * (grossAnnualSalary! + (grossAnnualSalary! * employerContributionRateOnSalary! / 100)) / 100) / 12) || 0)}.-</td>
                <td>CHF {(Math.round(((desiredActivityRate * (grossAnnualSalary! + (grossAnnualSalary! * employerContributionRateOnSalary! / 100)) / 100)) / ((annualWorkableDays! * desiredActivityRate! / 100) - numberOfPaidDaysOff!)) || 0)}.-</td>
              </tr>
              <tr>
                <td>Montant versé au crafter</td>
                <td>CHF {(Math.round(((((desiredActivityRate * (grossAnnualSalary! + (grossAnnualSalary! * employerContributionRateOnSalary! / 100)) / 100)) / ((annualWorkableDays! * desiredActivityRate! / 100) - numberOfPaidDaysOff!)) - (((((desiredActivityRate * (grossAnnualSalary! + (grossAnnualSalary! * employerContributionRateOnSalary! / 100)) / 100)) / ((annualWorkableDays! * desiredActivityRate! / 100) - numberOfPaidDaysOff!)) * socraftMargin) / 100)) * (annualWorkableDays! * desiredActivityRate! / 100) - numberOfPaidDaysOff!) || 0)}.-</td>
                <td>CHF {(Math.round((((((desiredActivityRate * (grossAnnualSalary! + (grossAnnualSalary! * employerContributionRateOnSalary! / 100)) / 100)) / ((annualWorkableDays! * desiredActivityRate! / 100) - numberOfPaidDaysOff!)) - (((((desiredActivityRate * (grossAnnualSalary! + (grossAnnualSalary! * employerContributionRateOnSalary! / 100)) / 100)) / ((annualWorkableDays! * desiredActivityRate! / 100) - numberOfPaidDaysOff!)) * socraftMargin) / 100)) * (annualWorkableDays! * desiredActivityRate! / 100) - numberOfPaidDaysOff!) / 12) || 0)}.-</td>
                <td>CHF {(Math.round((((desiredActivityRate * (grossAnnualSalary! + (grossAnnualSalary! * employerContributionRateOnSalary! / 100)) / 100)) / ((annualWorkableDays! * desiredActivityRate! / 100) - numberOfPaidDaysOff!)) - (((((desiredActivityRate * (grossAnnualSalary! + (grossAnnualSalary! * employerContributionRateOnSalary! / 100)) / 100)) / ((annualWorkableDays! * desiredActivityRate! / 100) - numberOfPaidDaysOff!)) * socraftMargin) / 100)) || 0)}.-</td>
              </tr>
            </table>
          </TablesLayer>
          <ActionsLayer>
            <button
              className="secondary"
              disabled={clientType === null}
              onClick={() => handleAction()}>
              Je veux {clientType !== null ? texts[clientType] : "..."}
              <IconArrowRight />
            </button>
          </ActionsLayer>
        </ContentLayer>
      </PageLayer>
    </>
  );
};

export default App;

import { IconArrowRight, IconAt, IconPencil, IconUser } from "@tabler/icons-react";
import { ContactFields, ContactProps } from "../types/contact";
import { getMailTemplate } from "../utils/getMailTemplate";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase.config";
import { useForm } from "react-hook-form";
import { ReactElement } from "react";
import Swal from "sweetalert2";

const Contact = ({ title, clientType }: ContactProps): ReactElement => {
  const { register, handleSubmit } = useForm();

  const handleAction = async ({ lastname, email, object }: ContactFields): Promise<void> => {
    const html = getMailTemplate({ lastname, email, object }, clientType);
    const sendMail = httpsCallable(functions, "sendMail");

    try {
      await sendMail({
        to: "info@socraft.ch",
        from: email,
        subject: "pricing.socraft.ch - retour",
        html,
      });

      Swal.fire({
        icon: "success",
        title: "Merci !",
        text: "Vous serez recontacté dans les plus brefs délais",
        confirmButtonText: "<span style='color: #282828; font-weight: 800;'>Ok</span>",
        confirmButtonColor: "#fbbb10",
      });
    } catch (error: any) {
      Swal.fire({
        icon: "error",
        title: "Oops !",
        text: "Une erreur est survenue ... Veuillez réessayer",
        confirmButtonText: "<span style='font-weight: 800;'>Ok</span>",
        confirmButtonColor: "#282828",
      });

      throw new Error(error);
    }
  };

  return (
    <div className="popup-content">
      <h1>Je veux {title}</h1>
      <form onSubmit={handleSubmit(handleAction)}>
        <div className="input-group">
          <IconUser />
          <input
            type="text"
            placeholder="Nom"
            {...register("lastname", { required: true })}
          />
        </div>
        <div className="input-group">
          <IconAt />
          <input
            type="email"
            placeholder="Adresse E-Mail"
            {...register("email", { required: true })}
          />
        </div>
        <div className="input-group">
          <IconPencil />
          <textarea
            {...register("object")}
            placeholder="Objet (facultatif)"
          ></textarea>
        </div>
        <button type="submit" className="secondary">
          Envoyer
          <IconArrowRight />
        </button>
      </form>
    </div>
  );
};

export default Contact;

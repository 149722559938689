import { ContactFields } from "../types/contact";
import texts from "../static/buttonTexts.json";

export const getMailTemplate = ({ lastname, email, object }: ContactFields, action: string): string => {
  const template = `
    <h3>Une personne souhaite ${action === texts["crafter"] ? "Être portée par socraft" : action} ! (via pricing.socraft.ch)</h3>
    <ul>
      <li>Nom : ${lastname}</li>
      <li>email : ${email}</li>
      <li>Objet : ${object || ("<i>pas d'objet</i>")}</li>
    </ul>
  `;

  return template;
};
